<template>
  <div class="contents-section-wrap">
    <v-container>
      <v-row
        no-gutters
        class="black--text"
      >
        <v-col
          cols="12"
          class="pt-4 pt-sm-8 pt-md-12 text-center text-h7 text-sm-h5 font-weight-bold"
        >
          업계최저비용, 많은 일감
          <br>
          간병인 여사님, 선생님들께
          <br>
          최적의 환경을 제공합니다.
        </v-col>
        <v-col
          cols="12"
          class="pt-4 pb-2 text-center text-body-2"
          style="color: #007AFF;"
        >
          <div
            class="text-description"
            style="font-size: 0.825rem !important;"
          >
            지금도 헬퍼 서비스에 환자가 등록되고 있어요.
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-0 pt-2 pb-8">
          <PatientCardWrapper />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PatientCardWrapper from '../patient/PatientCardWrapper'
// import caregiverApi from '../caregiver/api/caregiver'

export default {
  components: {
    PatientCardWrapper
  },
  data() {
    return {
      patients: [],
      cursor: 0
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    async init() {
      await this.getPatient()
    },
    async getPatient() {
      this.patients = [
        {
          age: 34,
          gender: 'female',
          weight: 58,
          area: '전라북도/전주',
          location: '전북대병원',
          status: '뇌졸증, 뇌졸증, 뇌졸증, 뇌졸증',
          need: '거동보조, 식사보조, 재활보조',
          date: '2021.05.01 ~ 2021.05.30'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.contents-section-wrap {
  background-color: #F9F9F9;
}
.text-description {
  background: url('./assets/icon-sms-failed.png') center left no-repeat;
  background-size: 20px;
  padding-left: 24px;
  padding-top: 3px;
  display: inline-block;
  height: 30px;
}
.patient-card {
// width: 260.01px;
min-height: 176.17px;
background: #FFFFFF;
box-shadow: 0px 1.78396px 8.9198px rgba(0, 0, 0, 0.1);
border-radius: 10px;
}
</style>
